<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- settings section -->
            <section class="settings-section section-gap-30">
                <SettingMenu></SettingMenu>
                <div class="setting-content">
                        <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
                            <div class="section-title margin-deliver">
                                {{ $t(`Manage Categories`) }}
                                <v-popover offset="10" :disabled="false" style="display:initial;">
                                    <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i></button>
                                    <template slot="popover">
                                        <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                        <div style="text-align:left;">
                                            <a v-close-popover href="https://easy.jobs/docs/manage-custom-job-categories" target="_blank">
                                                {{ $t(`How to create and manage custom job categories?`) }}
                                            </a>
                                        </div>

                                    </template>
                                </v-popover>
                            </div>

                            <button class="button primary-button text-capitalize" @click="showModal()">
                                <span class="icon"><i class="eicon e-plus"></i></span>
                                <span class="text-capitalize">{{ $t(`Add Category`) }}</span>
                            </button>
                        </div>
                        <!-- data table -->
                        <div class="data-table user-table" v-if="isContentLoading">
                            <TableLoader></TableLoader>
                        </div>
                        <div class="data-table user-table" v-else>
                            <h4 class="empty-message" v-show="isEmpty">{{ $t(`No categories found.`) }}</h4>
                            <div class="table-wrap" v-show="!isEmpty">
                                <div class="table">
                                    <div class="table__row table__head">
                                        <div class="table-cell">{{ $t(`Category Name`) }}</div>
                                        <div class="table-cell text-center">{{ $t(`Updated On`) }}</div>
                                        <div class="table-cell text-center">{{ $t(`Actions`) }}</div>
                                    </div>
                                    <div class="table__row" v-for="(category, index) in categories">
                                        <div class="table-cell user__info">{{ category.name }}</div>
                                        <div class="table-cell text-center">{{ dateFormat(category.updated_at) }}</div>
                                        <div class="table-cell user-action">
                                            <div class="d-flex align-items center">
                                                <button @click.prevent="showUpdateModal(category)" class="control-button control-button--primary has_bg_color">
                                                    <div class="control-button__icon">
                                                        <i class="eicon e-pencil"></i>
                                                    </div>
                                                    <span>{{ $t(`Update`) }}</span>
                                                </button>
                                                <button @click.prevent="deleteCategory(category, index)"
                                                        class="control-button control-button--danger has_bg_color">
                                                    <div class="control-button__icon">
                                                        <i class="eicon e-delete"></i>
                                                    </div>
                                                    <span>{{ $t(`Delete`) }}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <pagination v-if="!isEmpty" :pagination="paginateData" @paginate="getCategories"></pagination>
                        </div>

                        <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start section-gap">
                            <div class="section-title margin-deliver">
                                {{ $t(`Manage Skills`) }}
                                <v-popover offset="10" :disabled="false" style="display:initial;">
                                    <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i></button>
                                    <template slot="popover">
                                        <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                        <div style="text-align:left;">
                                            <a v-close-popover href="https://easy.jobs/docs/manage-custom-job-categories/#3-toc-title" target="_blank">
                                                {{ $t(`How to create and manage custom job skills?`) }}
                                            </a>
                                        </div>
                                    </template>
                                </v-popover>
                            </div>

                            <button class="button primary-button text-capitalize" @click="showSkillModal()">
                                <span class="icon"><i class="eicon e-plus"></i></span>
                                <span class="text-capitalize">{{ $t(`Add Skill`) }}</span>
                            </button>
                        </div>
                        <!-- data table -->
                        <div class="data-table user-table" v-if="isSkillContentLoading">
                            <TableLoader></TableLoader>
                        </div>
                        <div class="data-table user-table" v-else>
                            <h4 class="empty-message" v-show="isSkillEmpty">{{ $t(`No skills found.`) }}</h4>
                            <div class="table-wrap" v-show="!isSkillEmpty">
                                <div class="table">
                                    <div class="table__row table__head">
                                        <div class="table-cell">{{ $t(`Skill Name`) }}</div>
                                        <div class="table-cell text-center">{{ $t(`Updated On`) }}</div>
                                        <div class="table-cell text-center">{{ $t(`Actions`) }}</div>
                                    </div>
                                    <div class="table__row" v-for="(skill, index) in skills">
                                        <div class="table-cell user__info">{{ skill.name }}</div>
                                        <div class="table-cell text-center">{{ dateFormat(skill.updated_at) }}</div>
                                        <div class="table-cell user-action">
                                            <div class="d-flex align-items center">
                                                <button @click.prevent="showUpdateSkillModal(skill)" class="control-button control-button--primary has_bg_color">
                                                    <div class="control-button__icon">
                                                        <i class="eicon e-pencil"></i>
                                                    </div>
                                                    <span>{{ $t(`Update`) }}</span>
                                                </button>
                                                <button @click.prevent="deleteSkill(skill, index)"
                                                        class="control-button control-button--danger has_bg_color">
                                                    <div class="control-button__icon">
                                                        <i class="eicon e-delete"></i>
                                                    </div>
                                                    <span>{{ $t(`Delete`) }}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <pagination v-if="!isSkillEmpty" :pagination="paginateSkillData" @paginate="getSkills"></pagination>
                        </div>
                </div>

            </section>
            <modal-add-category v-if="addCategory" :category="category" @closeCreateModal="hideCategoryModal" @reloadCategory="reloadCategory"></modal-add-category>
            <modal-add-skill v-if="addSkill" :skill="skill" @closeCreateModal="hideSkillModal" @reloadCategory="getSkills"></modal-add-skill>
        </div>
    </dashboard-layout>
</template>
<script>

const DashboardLayout = () => import("../../../layouts/DashboardLayout");
const SettingMenu  = () => import("../../../components/company/SettingSidebar");
const ModalAddCategory = () =>  import("../../../components/modal/company/AddCategory");
const ModalAddSkill = () =>  import("../../../components/modal/company/AddSkill");
const Pagination = () =>  import("../../../components/pagination/Pagination");
const TableLoader = () =>  import("../../../components/_loaders/_TableLoader");

import client from "../../../app/api/Client";
import {mapState} from 'vuex';

export default {
    components: {
        Pagination,
        DashboardLayout,
        SettingMenu,
        ModalAddCategory,
        TableLoader,
        ModalAddSkill
    },

    data() {
        return {
            isContentLoading: true,
            categories: [],
            addCategory: false,
            addSkill: false,
            paginateData: [],
            category: {},
            skill: {},
            paginateSkillData: [],
            skills: [],
            isSkillContentLoading: false
        }
    },

    computed: {
        ...mapState(['user', 'company']),
        isEmpty() {
            return this.categories.length === 0;
        },
        isSkillEmpty() {
            return this.skills.length === 0;
        }
    },
    methods: {
        hideCategoryModal() {
            this.addCategory = false;
            const el = document.body;
            el.classList.remove('modal-open');
            this.category = {};
        },
        hideSkillModal() {
            this.addSkill = false;
            const el = document.body;
            el.classList.remove('modal-open');
            this.skill = {};
        },
        async getCategories(page = 1) {
            this.isContentLoading = true;
            try {
                let {data: {data}} = await client().get('/company/setting/category', {
                    params: {
                        page: page
                    }
                });
                this.categories = data.data;
                _.unset(data.data);
                this.paginateData = data;
            } catch (e) {
            }
            this.isContentLoading = false;
        },
        async getSkills(page = 1) {
            this.isSkillContentLoading = true;
            try {
                let {data: {data}} = await client().get('/company/setting/skill', {
                    params: {
                        page: page
                    }
                });
                this.skills = data.data;
                _.unset(data.data);
                this.paginateSkillData = data;
            } catch (e) {
            }
            this.isSkillContentLoading = false;
        },

        showModal() {
            this.addCategory = true;
            const el = document.body;
            el.classList.add('modal-open');
        },
        showSkillModal() {
            this.addSkill = true;
            const el = document.body;
            el.classList.add('modal-open');
        },
        showUpdateModal(category) {
            this.category = category;
            this.showModal();
        },
        showUpdateSkillModal(skill) {
            this.skill = skill;
            this.showSkillModal();
        },
        deleteCategory(category, index) {
            let categoryId = category.id;
            if (category.use_count > 0) {
                let message = {
                    title: this.$t(`Confirmation`),
                    body: this.$t(`This category is used in one or many jobs. Are you sure, you want to remove this category?`)
                };
                let dialogConfig = {
                    okText: this.$t('yes'),
                    cancelText: this.$t('no'),
                };
                this.$dialog.confirm(message, dialogConfig).then(() => {
                    this.deleteApiCall(categoryId, index);
                });
            } else {
                this.deleteApiCall(categoryId, index);
            }
        },
        deleteApiCall(categoryId, index) {
            client().delete(`company/setting/category/${categoryId}`)
                .then(({data: {data, message}}) => {
                    this.categories.splice(index, 1);
                    this.$toast.success(this.$t(message));
                }).catch((err) => {
            });
        },
        deleteSkill(skill, index) {
            let skillId = skill.id;
            if (skill.use_count > 0) {
                let message = {
                    title: this.$t(`Confirmation`),
                    body: this.$t(`This skill is used in one or many jobs. Are you sure, you want to remove this skill?`)
                };
                let dialogConfig = {
                    okText: this.$t('yes'),
                    cancelText: this.$t('no'),
                };
                this.$dialog.confirm(message, dialogConfig).then(() => {
                    this.deleteSkillApiCall(skillId, index);
                });
            } else {
                this.deleteSkillApiCall(skillId, index);
            }
        },
        deleteSkillApiCall(skillId, index) {
            client().delete(`company/setting/skill/${skillId}`)
                .then(({data: {data, message}}) => {
                    this.skills.splice(index, 1);
                    this.$toast.success(this.$t(message));
                }).catch((err) => {
            });
        },
        async reloadCategory() {
            await this.getCategories();
        }
    },
    async mounted() {
        await this.getCategories();
        await this.getSkills();
    }
}
</script>
